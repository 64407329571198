.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

table {
  font-family: "Open Sans", sans-serif;
}

table tr:last-child > td {
    border-bottom: none!important;
}

table td {
  font-weight: 400;
  font-size: 16px!important;
  line-height: 22px!important;
}

.calamar-card {
  border-radius: 8px;
  box-shadow: 0px 0px 64px rgba(98, 151, 163, 0.13);
  padding: 24px;
  background-color: white;
}

@media (min-width: 900px) {
  .calamar-card {
    padding: 48px;
  }
}

.calamar-table-header {
  font-weight: 700!important;
  font-size: 24px!important;
  line-height: 33px!important;
}

.calamar-info-table td, th {
  font-family: "Open Sans", sans-serif;
}

.calamar-info-table td:first-child {
  padding-left: 0;
}

.calamar-info-table > tbody > tr > td:first-child {
  font-weight: 700!important;
  padding-left: 0;
}

.calamar-info-table td:not(:first-child) {
  word-break: break-all;
}

.calamar-table td:first-child, th:first-child {
  padding-left: 0;
}

.calamar-table td, th {
  font-family: "Open Sans", sans-serif!important;
}

.calamar-table th {
  font-weight: 700!important;
  font-size: 16px;
  line-height: 22px;
}

a {
  color: #14A1C0;
  text-decoration: none;
  font-family: 'Google Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.MuiTooltip-arrow {
  color: black!important;
}

.MuiTooltip-tooltip {
  font-family: 'Open Sans'!important;
  font-style: normal;
  font-weight: 400!important;
  font-size: 16px!important;
  line-height: 20px!important;
  padding-right: 16px!important;
  padding-left: 16px!important;

  /* or 125% */
  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF!important;

  background-color: black!important;
}

.calamar-button {
  text-transform: capitalize !important;
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #ffffff !important;
  height: 56px;
}

li.MuiMenuItem-root {
    text-transform: capitalize !important;
  }
